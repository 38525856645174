#manage .ftxmain-mode {
  .ftx-page {
    margin-top: 24px;
  }
}

#manage .ftxmain-mode .subaccount-page {
  &>.options {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }
}

#manage .ftxmain-mode .withdraw-page {
  &>.options {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }

  .withdraw-table {
    .notes {
      opacity: 0.5;
      font-size: 12px;
    }
  }
}

.bulk-create-address-size-input {
  &-opts {
    margin-bottom: 8px;
  }
  &-table-row {
    margin-bottom: 8px;
    display: flex;
    column-gap: 8px;

    &>input {
      flex-grow: 1;
      flex-shrink: 1;
    }

    &>.ant-input-number {
      flex-grow: 0;
      flex-shrink: 0;
    }

    &>button {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  &-table-add {
    width: 100%;
  }
}

#manage .ftxmain-mode .collect-page {
  &>.options {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }

  &>.table {
    margin-bottom: 24px;
  }

  .balance-table {
    .nickname {
      width: 10%;
    }
    .balances {
      width: 90%;
      padding: 0;
    }
  }
  .balance-sub-table {
    .ant-table {
      margin: 0;
    }

    .coin {
      width: 20%;
    }
    .free {
      width: 20%;
    }
    .spotBorrow {
      width: 20%;
    }
    .availableWithoutBorrow {
      width: 20%;
    }
    .availableForWithdrawal {
      width: 20%;
    }
  }
}
