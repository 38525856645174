#manage {
  .header {
    background-color: var(--primary-color);
    color: #fff;
    margin-bottom: 24px;

    .container {
      display: flex;
      justify-content: space-between;
    }
  }
}
