#home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
#home .content {
  width: 600px;
  border: var(--primary-color) solid 1px;
  border-radius: 5px;
  padding: 24px;

  .steps {
    margin-bottom: 24px;
  }
  .step-content {
    margin-bottom: 24px;
  }
  .step-nav {
    text-align: center;
  }
}
#home .version {
  text-align: center;
  opacity: 0.5;
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
