#manage .ftxsub-mode {
  .ftx-page {
    margin-top: 24px;
  }
}

#manage .ftxsub-mode .balance-page {
  &>.options {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }

  .balance-table {
    .nickname {
      width: 10%;
    }
    .balances {
      width: 90%;
      padding: 0;
    }
  }
  .balance-sub-table {
    .ant-table {
      margin: 0;
    }

    .coin {
      width: 20%;
    }
    .free {
      width: 20%;
    }
    .spotBorrow {
      width: 20%;
    }
    .availableWithoutBorrow {
      width: 20%;
    }
    .availableForWithdrawal {
      width: 20%;
    }
  }
}
