.progress-modal {
  .ant-modal-confirm-title {
    text-align: center;
  }

  &-content {
    text-align: center;
  }
  &-description {
    margin-top: 8px;
    font-size: 12px;
    opacity: 0.6;
  }
}